import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Accordion } from '../../index';

import * as S from './styled';

export default function Services() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          services {
            title
            items {
              title
              content
            }
          }
        }
      }
    }
  `);

  return (
    <S.Services id="services">
      <S.ServicesCard>
        <S.Title>{data.site.siteMetadata.services.title}</S.Title>
        <Accordion items={data.site.siteMetadata.services.items} />
      </S.ServicesCard>
    </S.Services>
  );
}
