import styled from 'styled-components';

import { Light } from '../styled';

export const Line = styled(Light)`
  display: block;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 1200px) {
    text-align: left;
  }
`;

export const Signature = styled.div`
  margin: 4rem 0 0 0;
`;
