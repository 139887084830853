import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function Signature({ author, publishYear, title }) {
  const years = () => {
    const current = new Date().getFullYear().toString();
    if (current === publishYear) return current;
    return `${publishYear}-${current}`;
  };

  return (
    <S.Signature>
      <S.Line>
        Copyright © {years()}. {title}
      </S.Line>
      <S.Line>
        Feito com{' '}
        <span role="img" aria-label="coração" alt="coração" title="coração">
          ❤
        </span>
        ️ na {author}
      </S.Line>
    </S.Signature>
  );
}

Signature.propTypes = {
  author: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publishYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
