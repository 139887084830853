import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Navigation } from 'styled-icons/boxicons-solid';
import { Title } from '../../../index';

import * as S from './styled';

export default function Map() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            map {
              title
            }
          }
        }
      }
      staticMap {
        center
        childFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  function onWazeClickHandler() {
    const baseLink = 'https://www.waze.com/ul?navigate=yes&zoom=17';
    const routeLink = `${baseLink}&q=${data.staticMap.center}`;
    window.open(routeLink, '_blank');
  }

  function onMapsClickHandler() {
    const baseLink = 'https://maps.google.com?saddr=Current+Location';
    const routeLink = `${baseLink}&daddr=${data.staticMap.center}`;
    window.open(routeLink, '_blank');
  }

  return (
    <S.Card>
      <S.Map
        title="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.709624802128!2d-47.441399!3d-23.5069663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf60257c10f425%3A0xca2a9341da96d2ea!2sR.%20Cel.%20Nogueira%20Padilha%2C%201205%20-%20Vila%20Hort%C3%AAncia%2C%20Sorocaba%20-%20SP%2C%2018020-002!5e0!3m2!1spt-BR!2sbr!4v1683991235758!5m2!1spt-BR!2sbr"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
      <S.Container>
        <Title>{data.site.siteMetadata.contacts.map.title}</Title>
        <S.Actions>
          <S.Action onClick={onWazeClickHandler}>
            <Navigation size={20} />
            Waze
          </S.Action>
          <S.Action onClick={onMapsClickHandler}>
            <Navigation size={20} />
            Google Maps
          </S.Action>
        </S.Actions>
      </S.Container>
    </S.Card>
  );
}
