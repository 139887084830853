import styled from 'styled-components';

export const IconContainer = styled.span`
  align-items: center;
  display: flex;
  height: 8rem;
  justify-content: center;
  margin: 0 0 1.6rem 0;
  width: 100%;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.sizes.h2};
  font-weight: ${({ theme }) => theme.weights.strong};
  line-height: ${({ theme }) => theme.lineHeights.title};
  margin: 0 0 1rem 0;
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.sizes.p};
  line-height: ${({ theme }) => theme.lineHeights.p};
`;

export const FeaturedItem = styled.article`
  > ${Title}, > ${Description} {
    color: ${({ theme }) => theme.colors.dark.hex()};
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: 1023px) {
    :not(:last-of-type) {
      margin: 0 0 6.4rem 0;
    }
  }
`;
