import styled from 'styled-components';

import { Button, Card as RawCard } from '../../../index';

export const Card = styled(RawCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 0 4.8rem 0;

  @media screen and (min-width: 1024px) {
    height: 68rem;
    box-shadow: none;
    margin: 0;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    height: 64rem;
    flex-shrink: 0;
    position: relative;
    width: 64rem;
  }
`;

export const Map = styled.iframe`
  height: auto;
  max-height: 32rem;
  min-height: 40rem;
  width: 100%;

  @media screen and (min-width: 1024px) {
    max-height: 68rem;
    height: 68rem;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.light.hex()};
  padding: 3.2rem;

  @media screen and (min-width: 1024px) {
    margin: 0;
  }

  @media screen and (min-width: 1200px) {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: fit-content;
  }
`;

export const Action = styled(Button)`
  padding: 1rem 3.2rem;
  white-space: nowrap;
  > * {
    flex-shrink: 0;
  }
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  > ${Action}:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }

  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    justify-content: center;

    > ${Action}:not(:last-of-type) {
      margin: 0 1rem 0 0;
    }
  }
`;