import styled from 'styled-components';

import { Card } from '../../index';

export const Services = styled.section`
  /* background: ${({ theme }) => theme.gradients.primary}; */
  background: url("./pattern.png");
  background-repeat: repeat;
  padding: 6.4rem 3.2rem 19.2rem 3.2rem;

  @media screen and (min-width: 768px) {
    padding: 6.4rem 6.4rem 19.2rem 6.4rem;
  }

  @media screen and (min-width: 1200px) {
    padding: 6.4rem 12.8rem 19.2rem 12.8rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 12.8rem calc((100vw - 1400px) / 2) 6.4rem
      calc((100vw - 1400px) / 2);
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.dark.hex()};
  font-size: ${({ theme }) => theme.sizes.h1};
  font-weight: ${({ theme }) => theme.weights.strong};
  line-height: ${({ theme }) => theme.lineHeights.title};
  text-align: center;
  margin: 0 0 3.2rem 0;
`;

export const ServicesCard = styled(Card)`
  padding: 3.2rem;
`;
