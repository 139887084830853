import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import HomeBgSm from '../../../assets/images/home-background-sm.jpg';
import HomeBgLg from '../../../assets/images/home-background-lg.jpg';
import HomeBgXl from '../../../assets/images/home-background-xl.jpg';

export const Home = styled.section`
  align-items: center;
  background: url(${HomeBgSm}), linear-gradient(to right bottom, rgba(242,81,0,.8), rgba(207, 122, 12, .5));
  // TODO remove !important
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  clip-path: polygon(50% 0%, 100% 0, 100% 100%, 24% 94%, 0 100%, 0 0);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  padding: 8rem 3.2rem 0 3.2rem;
  background-blend-mode: multiply;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) {
    clip-path: polygon(
      100% 0,
      100% 50%,
      100% 96%,
      60% 100%,
      30% 96%,
      0 100%,
      0 0
    );
  }

  @media screen and (min-width: 1024px) {
    background: url(${HomeBgLg}), linear-gradient(to right bottom, rgba(242,81,0,.8), rgba(207, 122, 12, .5));
  }

  @media screen and (min-width: 1400px) {
    background: url(${HomeBgXl}), linear-gradient(to right bottom, rgba(242,81,0,.8), rgba(207, 122, 12, .5));
  }
`;

export const Logo = styled(GatsbyImage)`
  margin: 0 0 1.6rem 0;
`;

export const Title = styled.h1`
  overflow: hidden;
  height: 0;
  width: 0;
`;

export const Description = styled.h2`
  color: ${({ theme }) => theme.colors.lightest.hex()};
  font-size: ${({ theme }) => theme.sizes.h2};
  font-weight: ${({ theme }) => theme.weights.strong};
  line-height: 144%;
  text-align: center;
`;

export const Actions = styled.div`
  margin: 2.4rem 0 4rem 0;
  width: 100%;
  max-width: 40rem;
  > button:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }

  @media screen and (min-width: 768px) {
    margin: 4rem 0 0 0;

    > button:nth-of-type(3) {
      margin: 0 0 6.4rem 0;
    }

    > button:last-of-type {
      bottom: 64px;
      left: 50%;
      position: relative;
      transform: translate(-50%, 0);
      width: 40rem;
    }
  }

  @media (min-width: 1024px) {
    > button:nth-of-type(3) {
      margin: 0 0 4.8rem 0;
    }
    > button:last-of-type {
      bottom: 48px;
    }
  }
`;
