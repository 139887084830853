import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styled';

export default function Select({
  className,
  label,
  name,
  options,
  value,
  onInput,
  ...rest
}) {
  return (
    <S.Input className={className}>
      <S.Label htmlFor={name}>{label}</S.Label>
      <S.Select
        id={name}
        name={name}
        value={value}
        onChange={onInput}
        {...rest}
      >
        <S.Option value="" className="--disabled">
          Selecione...
        </S.Option>
        {options.map((option) => (
          <S.Option key={option.value} value={option.value}>
            {option.label}
          </S.Option>
        ))}
      </S.Select>
    </S.Input>
  );
}

Select.defaultProps = {
  className: '',
  value: '',
  onInput: () => {},
};

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onInput: PropTypes.func,
};
