import styled, { css } from 'styled-components';

const active = css`
  transform: rotate(45deg);

  ::before {
    margin: 8px 0 0 0;
    transform: rotate(-90deg);
  }

  ::after {
    margin: 0 0 8px 0;
  }
`;

export const TogglerIcon = styled.span`
  position: relative;
  transition: transform 400ms;
  will-change: transform;

  ::before,
  &,
  ::after {
    background-color: ${({ theme }) => theme.colors.lightest.hex()};
    display: block;
    height: 0.4rem;
    width: 2.4rem;
  }

  ::before,
  ::after {
    content: '';
    position: absolute;
    transition: margin 400ms, transform 400ms;
    will-change: margin, transform;
  }

  ::before {
    top: -8px;
  }

  ::after {
    bottom: -8px;
  }

  &.--active {
    ${active}
  }
`;
