import React from 'react';
import PropTypes from 'prop-types';

import { LocationOn, Phone, AlternateEmail } from 'styled-icons/material';
import { Whatsapp } from 'styled-icons/boxicons-logos';

import * as S from './styled';

const CHANNEL_TYPE = {
  title: PropTypes.string.isRequired,
  display: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
  obs: PropTypes.string,
};

const ICONS = {
  Emails: <AlternateEmail />,
  Localização: <LocationOn />,
  Telefones: <Phone />,
  WhatsApp: <Whatsapp />,
};

function Channel({ title, display, obs }) {
  return (
    <S.Channel>
      <S.Icon>{ICONS[title]}</S.Icon>
      <S.Content>
        <S.Title>{title}</S.Title>
        {display.map((each) =>
          each.url ? (
            <S.DisplayLink
              href={each.url}
              target="_blank"
              rel="noreferrer noopener"
              key={each.text}
            >
              {each.text}
            </S.DisplayLink>
          ) : (
            <S.Display key={each.text}>{each.text}</S.Display>
          ),
        )}
        {obs && <S.Obs>{obs}</S.Obs>}
      </S.Content>
    </S.Channel>
  );
}
Channel.defaultProps = { obs: '' };
Channel.propTypes = CHANNEL_TYPE;

export default function Channels({ title, items }) {
  return (
    <S.Channels>
      <S.ListTitle>{title}</S.ListTitle>
      <S.Items>
        {items.map((each) => (
          <Channel key={each.title} {...each} />
        ))}
      </S.Items>
    </S.Channels>
  );
}
Channels.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(CHANNEL_TYPE)).isRequired,
};
