import React from 'react';
import PropTypes from 'prop-types';

import {
  Linkedin,
  Facebook,
  Instagram,
  Youtube,
} from 'styled-icons/boxicons-logos';

import * as S from './styled';

const ICONS = {
  Facebook: <Facebook />,
  Instagram: <Instagram />,
  LinkedIn: <Linkedin />,
  YouTube: <Youtube />,
};

const SOCIAL_TYPE = {
  display: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

function Social({ display, title, url }) {
  return (
    <S.Social>
      <S.Icon>{ICONS[title]}</S.Icon>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Display href={url} target="_blank" rel="noreferrer; noopener">
          {display}
        </S.Display>
      </S.Content>
    </S.Social>
  );
}
Social.propTypes = SOCIAL_TYPE;

export default function Socials({ title, items }) {
  return (
    <S.Socials>
      <S.ListTitle>{title}</S.ListTitle>
      <S.Items>
        {items.map((each) => (
          <Social key={each.url} {...each} />
        ))}
      </S.Items>
    </S.Socials>
  );
}
Socials.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(SOCIAL_TYPE)).isRequired,
};
